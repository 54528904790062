// Migrated
<template lang="pug">
component.dropdown__toggle(
  :is="as"
  role="button"
  :aria-expanded="$parent.isOpened()"
  :aria-controls="$parent.isOpened() ? `${$parent.id}-menu` : false"
  :style="{ height, width }"
  v-bind="$attrs"
)
  slot
</template>

<script>
// https://gist.github.com/plmrlnsnts/4587f5856608140609f27a29d77d052f
export default defineNuxtComponent({
  props: {
    as: {
      type: String,
      default: 'div'
    },

    height: {
      type: String,
      default: '100%'
    },

    width: {
      type: String,
      default: '100%'
    }
  },
})
</script>
